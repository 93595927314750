import { Modal } from 'react-bootstrap';
import React, { useEffect, useState } from 'react'
import classes from './AddGateModal.module.css'
import { http } from '../../services/index'

function AddGateModal({ show, setShow, onClick, setState, setGateType }) {

    const handleClose = () => setShow(false);
    const [gateData, setGateData] = useState([])

    async function getGates() {
        const url = `gate`;
        const response = await http(url);
        if (response?.code == 200) {
            setGateData(response?.data)
        }
    }

    function handleClick(item) {
        setState('gate')
        onClick(item.slug)
        setGateType(item?.name?.toLowerCase())
        setShow(false)
    }

    useEffect(() => {
        getGates()
    }, [])


    const gate = gateData.map(item => (
        <button onClick={() => handleClick(item)}>{item.name}</button>
    ))

    return (

        <Modal show={show} onHide={handleClose} className={classes.modal}
            centered
        >

            <Modal.Body className={classes.body}>
                <h1>Add Gate</h1>
                <div className={classes.btnsBox}>
                    {gate}
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default AddGateModal