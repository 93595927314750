// const localIpv4 = "localhost";

// const LOCAL_DEV_PORT = 7000;

const localIpv4 = "190.92.153.146";

const LOCAL_DEV_PORT = 50599;

const isDevelopmentEnv = false;

// export const BASE_URL = `http://${localIpv4}:${LOCAL_DEV_PORT}/api`
export const BASE_URL = `https://vilofence.herokuapp.com/api`

export const MAP_KEY="AIzaSyCvYlPd-cnHRaG44kXxnIUevcDaEtUh9gE"
 