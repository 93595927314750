import React from 'react';
import ReactDOM from 'react-dom';
import App from './routes';
import 'antd/dist/antd.css'
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css'


ReactDOM.render(
  <React.Fragment>
    <App />
  </React.Fragment>
  ,

  document.getElementById('root')
);
