import React, { useContext, useState } from 'react'
import { Row, Col } from 'react-bootstrap'
import { stepperContext } from '../stepperContext'
import classes from './StepperCard.module.css'
import { IoChevronBack } from 'react-icons/io5'
import AddGateModal from '../AddGateModal'
import _ from 'lodash'


function StepThreeWhenLineCreated({
    setNext,
    setStepNo,
    state,
    noOfGates,
    setState,
    gate,
    length,
    dottedLength,
    setGateType,
    setModalVisible,
    fullLengthArray,
    setFullLengthArray,
    markers,
    setMarkers
}) {

    const [isAddGate, setIsAddGate] = useState(false)

    const {
        lineData, setLineData,
    } = useContext(stepperContext)

    function addGateAfterType(e) {
        setGateType(e)
        setState('gate')
        setIsAddGate(false)

    }

    function onNext() {
        const newLineData = _.cloneDeep(lineData);
        newLineData.length = length || 0;
        newLineData.gate = gate || '';
        newLineData.noOfGates = noOfGates || 0;
        setLineData(newLineData)
        setState('drag')
        setNext(p => ++p)
    }

    function onUndo() {
        if (state === 'draw') {
            const data = _.cloneDeep(fullLengthArray)
            data.pop()
            setFullLengthArray(data)
        }
        else {
            const data = _.cloneDeep(markers)
            data.pop()
            setMarkers(data)
        }
    }

    return (
        <>
            <Col md={12}>
                <h4>
                    <IoChevronBack color="#272932" className={classes.backIcon} onClick={() => setStepNo(1)} />
                    Draw line and Add Gate
                </h4>
                <p className={classes.mt10}>Click on start drawing to draw your fence. </p>
            </Col>
            <div className={classes.cardBody}>
                <Row>
                    <Col md={12}>

                        <div className={`${classes.mt30} d-flex justify-content-between`}>
                            {
                                state === 'drag' &&
                                <button className={`${classes.btn} mx-2 ${classes.btn1}`} onClick={() => setState('draw')} >Start Drawing</button>
                            }
                            {
                                state === 'draw' &&
                                <>
                                    <button className={`${classes.btn} mx-2 ${classes.btn1}`} onClick={() => setModalVisible(true)} >Add Gate</button>
                                    {
                                        fullLengthArray?.length ?
                                            <button className={`${classes.btn} mx-2 ${classes.btn1}`} onClick={onUndo} >Undo</button> : <></>
                                    }
                                </>
                            }
                            {
                                state === 'gate' &&
                                <>
                                    <button className={`${classes.btn} mx-2 ${classes.btn1}`} onClick={onNext} >Next</button>
                                    {
                                        markers?.length ?
                                            <button className={`${classes.btn} mx-2 ${classes.btn1}`} onClick={onUndo} >Undo</button> : <></>
                                    }
                                    <button className={`${classes.btn} mx-2 ${classes.btn1}`} onClick={() => setState('draw')} >Back</button>
                                </>
                            }
                        </div>
                    </Col>
                    <Col md={12} >
                        <div className={`${[classes.mb10, classes.mt10].join(" ")}`}>
                            <h6>Fences</h6>

                            {length == 0 ? <p>No data</p> :
                                <>
                                    <div className='mb-2 d-flex align-items-center'>
                                        <h6>
                                            {length || 0} Ft.
                                            {
                                                dottedLength ?
                                                <span style={{ color: 'gray', fontWeight: '500' }}> + {dottedLength} Ft.</span>
                                                :
                                                <></>
                                            }
                                        </h6>
                                    </div>
                                    <p className={` ${[classes.mt10].join(" ")}`}>{noOfGates > 0 ? `${noOfGates} gates` : "No any get added yet"}</p>

                                </>
                            }
                            {/* <div className={`d-flex justify-content-center ${[classes.pb40, classes.mt20].join(" ")}`}>
                        <button className={classes.btn} onClick={onNext}>Next</button>
                    </div> */}
                        </div>
                        <AddGateModal show={isAddGate} setShow={setIsAddGate} onClick={addGateAfterType} />
                    </Col>
                </Row>
            </div>

        </>
    )
}

export default StepThreeWhenLineCreated;
