import React from 'react'
import { CancelIcon } from '../../assets'
import { BASE_URL } from '../../constants'
import classes from './ImageCardWithTitle.module.css'
function ImageCardWithTitle({ data, onClick, state }) {
    console.log({ data, state }, data?.slug == state)
    return (
        <div className={`${[classes.card,
        state == data?.slug &&
        classes.selected].join(" ")}`} >
            <img src={data?.image_url} className={classes.img} onClick={() => onClick(data?.slug)} />
            <p className={'mt-1'} onClick={() => onClick(data?.slug)}>{data?.title}</p>
           {state == data?.slug && <div
                className={classes.crossIcon}
                onClick={() => onClick({})}
            >
                <img src={CancelIcon} />
            </div>}

        </div>
    )
}

export default ImageCardWithTitle

