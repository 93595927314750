import React from 'react'
import classes from './ReviewCard.module.css'
import UserImg from '../../assets/user.png'
import { FaQuoteLeft } from 'react-icons/fa'
import ReactStarRatings from 'react-star-ratings'

function ReviewCard({ data }) {
    return (
        <div className={classes.card}>
            <div className={classes.profile}><img src={data?.profile} /></div>
            <h1>{data?.userName}</h1>
            <p className={classes.time}>{data?.createdAt}</p>
            <p className={classes.desc}>{data?.description}</p> 
            <div className={classes.ratingAndLikes}>
                <div>
                    <ReactStarRatings
                        numberOfStars={5}
                        rating={data?.rating}
                        starDimension={17}
                        starSpacing={2}
                        starRatedColor={'#FFE236'}
                        starEmptyColor={'#E7ECEF'}
                    />
                </div>
                <div><FaQuoteLeft color='#E7ECEF' className={classes.quote} 
                /></div>
            </div>

        </div>
    )
}

export default ReviewCard