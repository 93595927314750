import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import CommonHeader from '../../components/CommonHeader'
import ContactUsForm from '../../components/ContactUsForm'
import Footer from '../../components/Footer'
import classes from './LandingPage.module.css'
import { useNavigate } from 'react-router-dom'

function LandingPage() {
    
    const navigate = useNavigate()

    const data = [
        { img: '/user-img1.jpg', title: 'Professional Team', desc: 'Our mission is to educate, train, and inspire our people so that each team member is a leader of character committed to the values of respect, accountability, and integrity to service to our customers.' },
        { img: '/user-img2.jpg', title: 'A+ Customer Service', desc: 'Our team delivers friendly, responsive and attentive service, quality fence products and great communication.' },
        { img: '/user-img3.jpg', title: 'Quality Manager', desc: 'We fabricate and install quality fence products carefully selected and rigorously tested to perform in some of the harshest climates.' },
    ]

    const lastCards = [
        { title: 'Clean Up Your Yard.', description: 'Chances are, your yard is already in good shape if you give it regular maintenance. However, before our crew arrives, make sure you’ve cleared stray debris or other miscellaneous items off the ground that could interfere with the work.', img: '/clean-up1.jpg' },
        { title: 'Good Quality Fence.', description: "Great qualtiy of fence and neat and clean Installation to your yard. Can sparkle it's beauty create a custom fence and ask us to put it on you're garden to make you're garden look it's best and save. Put different types of gate in them to make it stand out", img: '/clean-up2.jpg' },
        { title: 'Get Your estimate now.', description: "Get estimated price just by filling out a simple questionaire form and get to know about different type of fence material and there estimated costing according to you're garden. Fine you're home and map and start drawing just after putting basic info in the pre-required steps.", img: '/clean-up3.jpg' },

    ]
    return (
        <div className={classes.landingPage}>
            <div className={classes.headerSection}>
                <CommonHeader />
                <div className={classes.overLay}>
                    <Container fluid className={classes.container}>
                        <Row className={classes.row}>
                            <Col className={classes.topLeft} md={6}>
                                <h6>NEW FENCE BUILDER</h6>
                                <h1>LUXURY YOU DESERVE</h1>
                                <p>
                                    Vilo Fence brings a standard of excellence that you
                                    can’t find among the competition.
                                    </p>
                                <button onClick={() => navigate('/calculator')} className={classes.btn}>Fence Calculator</button>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
            {/* Form */}
            <div className={classes.formSection}>
                <Container fluid className={classes.container}>
                    <ContactUsForm />
                </Container>
            </div>
            {/* Cards */}
            <div className={classes.cardsSection}>
                <Container fluid className={classes.container}>
                    <Row>
                        {data?.map(item => (
                            <Col md={4}>
                                <div className={classes.card}>
                                    <img src={item?.img} />
                                    <h3>{item?.title}</h3>
                                    <p>{item?.desc}</p>
                                </div>
                            </Col>
                        ))}
                    </Row>
                </Container>
            </div>
            {/* Fence Financing*/}
            <div className={classes.fenceFinancing}>

                <div className={classes.overLay}>
                    <Container fluid className={classes.container}>
                        <h3 className={classes.mb32}>Fence Financing</h3>
                        <p className={classes.mb32}>Let us help you design a fence that will add value and security to your property today. Terms of financing are variable based on amount borrowed, credit worthiness, and other factors. Contact us for specific details.</p>
                        <button>GET FINANCE OFFER</button>
                    </Container>
                </div></div>

            {/* Package Plan*/}
            <div className={classes.packageSection}>
                <Container fluid className={classes.container}>
                    <Row className={classes.row}>
                        <Col md={6} className={classes.left}>
                            <h2 className={classes.mb32}>Less than perfect Credit? No Problem!</h2>
                            <p className={classes.mb32}>We make it EASY to acquire the right fence. Get your financing offer today!</p>
                            <button>FINANCING AVAILABLE FOR 36-60 MONTHS</button>
                        </Col>
                        <Col md={6}>
                            <div className={classes.right}>
                                <h4>MONTHLY PAYMENT OPTIONS</h4>
                                <ul>
                                    <li>60 second pre-qualification</li>
                                    <li>Fund available in 1-3 days</li>
                                    <button>CHECK RATES</button>
                                </ul>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            {/* New Fence */}
            <div className={classes.newFenceInstallation}>
                <Container fluid className={classes.container}>
                    <p>Prepare your yard</p>
                    <h5>Before New Fence Installation</h5>
                    <p >Vilo Fence takes care of all the formalities so you can sit back and enjoy your new fence in an amazingly short amount of time.</p>
                </Container>
            </div>
            {/* Cards */}
            <div className={classes.lastCards}>
                <Container  className={classes.container}>

                    {
                        lastCards?.map((item, i) => (
                            <Row className={classes.cardBody}>

                                <Col md={{ span: 6, order: i == 1 && 3 }}>
                                    <div className={classes.imgBox}>
                                        <img src={item?.img} />
                                    </div>
                                </Col>
                                <Col md={{ span: 6, order: i == 1 && 2 }}>
                                    <div className={classes.content}>
                                        <h4>{item?.title}</h4>
                                        <p>{item?.description}</p>
                                    </div>
                                </Col>
                            </Row>

                        ))
                    }
                </Container>
            </div>


            <Footer />
        </div>

    )
}

export default LandingPage