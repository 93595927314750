import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import CommonHeader from '../../components/CommonHeader'
import ContactUsForm from '../../components/ContactUsForm'
import Footer from '../../components/Footer/index'
import classes from './ContactUs.module.css'

function ContactUs() {
    const navigate=useNavigate()

    return (
        <div className={classes.contactUs}>
            <CommonHeader />
            <div className={classes.headerSection}>
                <div className={classes.overLay}>
                    <Container fluid className={classes.container}>
                        <p className={classes.tagline}>Vilo Fence brings a standard of excellence that you
                            can’t find among the competition.</p>
                        <button className={classes.btn} onClick={() => navigate('/calculator')}>FENCE CALCULATOR</button>
                        <h1 className={classes.heading}>CONTACT US</h1>

                    </Container>
                </div>
            </div>
            <div className={classes.formContainer}>
                <Container className={classes.container}>
                    <ContactUsForm />
                </Container>
            </div>

            <Footer />
        </div>
    )
}

export default ContactUs