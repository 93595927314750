import React from 'react'
import classes from './Input.module.css'

function Input({ value, setter, type, label, comp }) {
    return (
        <div className={classes.inputWithLabel}>
            <label htmlFor={label} >{label}</label>
            {comp == 'textarea' ?
                <textarea id={label} value={value} onChange={(e) => setter(e.target.value)} />
                :
                <input type={type ? type : 'text'} id={label} value={value} onChange={(e) => setter(e.target.value)} />}
        </div>

    )
}

export default Input