import { Col, Container, Row } from 'react-bootstrap'
import React, { memo, useEffect, useMemo, useRef, useState } from 'react'
import StepperCard from '../../components/StepperCard'
import classes from './styles.module.css'
import Header from '../../components/CommonHeader'
import { stepperContext } from '../../components/stepperContext'
import { useNavigate } from 'react-router'
import CustomMap from '../MapPage'

function AddInfoAndFence() {
    const [selectedTypeToAdd, setSelectedTypeToAdd] = useState(null)
    const [lineData, setLineData] = useState([])
    const [index, setIndex] = useState(0)



    const navigate = useNavigate()

    return (
        <stepperContext.Provider value={{
            selectedTypeToAdd, setSelectedTypeToAdd,
            lineData, setLineData,
            index, setIndex,
        }}>

            <div className={`${[classes.addInfoPage].join(" ")}`}>
                <Header />

                <CustomMap 
                />

            </div>
        </stepperContext.Provider>
    )
}

export default AddInfoAndFence      