import React, { useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import Input from '../Input'
import classes from './ContactUsForm.module.css'
import { IoLocationOutline } from 'react-icons/io5'
import { AiOutlineMail, AiOutlinePhone } from 'react-icons/ai'
import http from '../../services/api'

export default function ContactUsForm() {
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [phone, setPhone] = useState('')
    const [email, setEmail] = useState('')
    const [message, setMessage] = useState('')


    async function postData() {
        const body = {
            firstName, lastName,
            phone,
            email,
            message
        }
        const url = 'message'
        const options = {
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify(body)
        }
        const res=await http(url, options)
        if(res?.code == 200){
            message.success('Message sent successfully')

        }
    }

    return (
        <div className={classes.contactUs}>
            <Row className='g-0'>
                <Col md={{ span: 5, order: 1 }} xs={{ span: 12, order: 2 }}>
                    <div className={classes.left}>
                        <h1>Contact information</h1>
                        <p>You can approach to us via these links
                            and ask any queries regarding us!</p>
                        <ul className={classes.mt50}>

                            <li><span><IoLocationOutline className={classes.icon} /></span><p>3805 W Osborne Ave,
                                Tampa, Fl 33614</p></li>
                            <li><span><AiOutlinePhone className={classes.icon} />
                            </span>
                                <p>+1(813)443-0771</p></li>
                            <li><span><AiOutlineMail className={classes.icon} />
                            </span>
                                <p>customerservice@vilofence.com</p></li>

                        </ul>
                    </div>

                </Col>
                <Col md={{ span: 7, order: 2 }} xs={{ span: 12, order: 1 }}>
                    <div className={classes.form}>
                        <h1>GET IN TOUCH</h1>
                        <p>
                            Contact us today to speak with one of our friendly team
                            members for more information about our selection of
                            fences. Monday to Friday 8AM - 5PM
                        </p>
                        <Row>
                            <Col md={6}>
                                <div className={classes.mt30}>
                                    <Input value={firstName} setter={setFirstName} label={'First Name'} />
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className={classes.mt30}>
                                    <Input value={lastName} setter={setLastName} label={'Last Name'} />
                                </div>

                            </Col>
                            <Col md={6}>
                                <div className={classes.mt30}>
                                    <Input value={phone} setter={setPhone} label={'Phone'} />
                                </div>

                            </Col>
                            <Col md={6}>
                                <div className={classes.mt30}>
                                    <Input value={email} setter={setEmail} label={'Email'} />
                                </div>

                            </Col>
                            <Col md={12}>
                                <div className={classes.mt30}>
                                    <Input comp={'textarea'} value={message} setter={setMessage} label={'Message'} />
                                </div>

                            </Col>
                            <Col md={12}>
                                <div className={`${classes.mt30} ${classes.btnBox}`}>
                                    <button onClick={postData}>Send</button>
                                </div>

                            </Col>
                        </Row>
                    </div>
                </Col >
            </Row >

        </div >
    )
}