import React, { useEffect, useState } from "react";
import {
    withScriptjs,
    withGoogleMap,
    GoogleMap,
    Polyline,
    Marker
} from "react-google-maps";
import _ from 'lodash'
import StepperCard from "../../components/StepperCard";
import AddGateModal from "../../components/AddGateModal";
import classes from './MapPage.module.css'
import { MAP_KEY } from '../../constants/api'

const InternalMap = props => {

    const [currentCoordinates, setCurrentCoordinates] = useState([])
    const [coordinates, setCoordinates] = useState([])  // 0
    const [dottedCoordinated, setDottedCoordinated] = useState([])
    const [dottedLength, setDottedLength] = useState(0)
    const [fullLengthArray, setFullLengthArray] = useState([])
    const [fullLength, setFullLength] = useState(0)
    const [gateType, setGateType] = useState('')
    const [visible, setVisible] = useState(false)
    const [gate, setGate] = useState('')
    const [markers, setMarkers] = useState([])
    const [state, setState] = useState('drag')
    const [place, setPlace] = useState({ lat: 44.500000, lng: -89.500000 })
    const [zoom, setZoom] = useState(10)

    function handleMapClick(e) {

        if (state === 'drag') {
            return
        }
        else if (state === 'gate') {
            let tempMarkers = _.cloneDeep(markers);
            tempMarkers.push({
                lat: e.latLng.lat(),
                lng: e.latLng.lng(),
            })
            setMarkers(tempMarkers)
        }
        else {
            setDottedCoordinated([])
            setDottedLength(0)

            let tempCurrentCord = currentCoordinates;

            let tempCord = coordinates;

            if (currentCoordinates.length >= 2) {
                tempCord.push(currentCoordinates)
                setCurrentCoordinates([])
            }
            else if (currentCoordinates.length < 2) {
                tempCurrentCord.push({
                    lat: e.latLng.lat(),
                    lng: e.latLng.lng()
                })
                setCurrentCoordinates(tempCurrentCord)
            }
            setCoordinates(tempCord)
            recheck()
            const lenthArray = updateLength(tempCord)
            setFullLengthArray(lenthArray)
            setFullLength(calculateFinalLength(lenthArray))
        }

    }

    function recheck() {

        let tempCurrentCord = currentCoordinates;

        let tempCord = coordinates;

        if (tempCurrentCord.length >= 2) {

            tempCord.push(currentCoordinates)
            setTimeout(() => setCoordinates(tempCord), 50)
            setCurrentCoordinates([])
            updateLength()


        }
    }

    function getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
        var R = 6371; // Radius of the earth in km
        var dLat = deg2rad(lat2 - lat1);  // deg2rad below
        var dLon = deg2rad(lon2 - lon1);
        var a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
            Math.sin(dLon / 2) * Math.sin(dLon / 2)
            ;
        var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        var d = R * c; // Distance in km
        d = d * 1000; // distance in meter
        d = d * 3.28084
        return Math.round(d)
    }

    function onMouseMove(e) {
        let tempCord = _.cloneDeep(currentCoordinates);
        if (tempCord.length === 2) {
            tempCord.pop()
        }
        if (currentCoordinates.length === 1) {
            tempCord.push({
                lat: e.latLng.lat(),
                lng: e.latLng.lng()
            })
            setDottedCoordinated(tempCord)
            const lengthRn = getDistanceFromLatLonInKm(
                tempCord[0].lat,
                tempCord[0].lng,
                tempCord[1].lat,
                tempCord[1].lng,
            )
            setDottedLength(lengthRn)
        }
        return
    }

    function deg2rad(deg) {
        return deg * (Math.PI / 180)
    }

    function updateLength(arr) {

        if (!arr?.length) return

        let tempDistance = arr.map(cord => {
            return (
                getDistanceFromLatLonInKm(
                    cord[0].lat,
                    cord[0].lng,
                    cord[1].lat,
                    cord[1].lng
                )
            )
        })


        return tempDistance
    }

    function calculateFinalLength(arr) {
        if (!arr?.length) return
        return arr.reduce((a, b) => Number(a) + Number(b), 0)
    }

    function zoomSetting() {
        const lat = parseInt(place.lat)
        const lng = parseInt(Math.abs(place.lng))
        if (lat != 44 && lng != 89) {
            setTimeout(() => {
                setZoom(20)
            }, 100)
        }
    }

    useEffect(() => {
        const tempLengths = updateLength(coordinates)
        setFullLengthArray(tempLengths)
        const tempFinalLength = calculateFinalLength(tempLengths)
        setFullLength(tempFinalLength)
    }, [coordinates])

    useEffect(() => {
        zoomSetting()
    }, [place])

    return (
        <GoogleMap
            zoom={zoom}
            center={place}
            onClick={(e) => handleMapClick(e)}
            onMouseMove={(e) => onMouseMove(e)}
            options={{ mapTypeId: "satellite", disableDefaultUI: true, tilt: 0 }}
        >
            <div fluid className={classes.container}>
                <StepperCard
                    length={fullLength}
                    dottedLength={dottedLength}
                    state={state}
                    setState={setState}
                    lines={fullLength}
                    noOfGates={markers?.length}
                    gate={gate}
                    setModalVisible={setVisible}
                    setGateType={setGateType}
                    setPlace={setPlace}
                    fullLengthArray={coordinates}
                    setFullLengthArray={setCoordinates}
                    markers={markers}
                    setMarkers={setMarkers}
                />
            </div>
            <AddGateModal
                setState={setState}
                onClick={setGate}
                setGateType={setGateType}
                show={visible}
                setShow={setVisible}
            />
            {
                coordinates?.map((item, index) => (
                    //  lineDistance =  fullLengthArray[index]
                    <Polyline
                        onClick={e => handleMapClick(e)}
                        key={`${Math.random() * 100000}`}
                        path={item}
                        options={{
                            strokeColor: "#A20427",
                            strokeOpacity: 0.9,
                            strokeWeight: 5,
                        }}
                    />
                ))
            }
            {markers.map(place => {
                return (
                    <Marker
                        key={`${Math.random() * 100000}`}
                        position={{ lat: place.lat, lng: place.lng }}
                        icon={{
                            url: gateType == 'single' ? '/single-gate.jpeg' : '/double-gate.jpeg',
                            scaledSize: new window.google.maps.Size(25, 25)
                        }}
                    />
                );
            })}
            {
                dottedCoordinated.length === 2 &&
                <Polyline
                    onClick={e => handleMapClick(e)}
                    path={dottedCoordinated}
                    options={{
                        strokeColor: "#A20427",
                        strokeOpacity: 0.7,
                        strokeWeight: 2,
                    }}
                />
            }
        </GoogleMap>
    )
};

const MapHoc = withScriptjs(withGoogleMap(InternalMap));

const MyMapComponent = props => {

    return (
        <>
            <MapHoc
                googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyCvYlPd-cnHRaG44kXxnIUevcDaEtUh9gE&v=3.exp&libraries=geometry,drawing,places"
                loadingElement={<div style={{ height: `100%` }} />}
                containerElement={<div style={{ height: `100vh` }} />}
                mapElement={<div className={classes.map} />}

            />
        </>
    )
};


export default MyMapComponent;