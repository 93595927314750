import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import classes from './footer.module.css'
import Logo from '../../assets/logo.png'
import { BsFillTelephoneFill, BsTwitter } from 'react-icons/bs'
import { IoLocationSharp } from 'react-icons/io5'
import { AiOutlineCopyrightCircle } from 'react-icons/ai'
import { FaFacebookF, FaInstagram, FaPinterestP, FaYoutube } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'

function Footer() {
    const navigate=useNavigate()
    function redirectOn(text){
        // window.location.href=`https://www.${text}.com`
        window.open(`https://www.${text}.com`,'_blank')
    }
    return (
        <>
            <div className={classes.footer}>
                <Container fluid className={classes.container}>
                    <Row className={classes.row}>
                        <Col lg={2} md={6}>
                            <h1>Quick Links</h1>
                            <ul>
                                <li onClick={()=>navigate('/')}>Home</li>
                                <li onClick={()=>navigate('/about-us')}>About Us</li>
                                <li onClick={()=>navigate('/gallery')}>Gallery</li>
                                <li onClick={()=>navigate('/client-reviews')}>Client Reviews</li>
                            </ul>
                        </Col>
                        <Col lg={2} md={6}>
                            <h1>Company</h1>
                            <ul>
                                <li onClick={()=>navigate('/about-us')}>About us</li>
                                <li onClick={()=>navigate('/contact-us')}>Contact us</li>
                            </ul>
                        </Col>
                        <Col lg={4} md={6}>
                            <h1>Location</h1>
                            <ul>
                                <li><IoLocationSharp /> 3805 W Osborne Ave Tampa, FL 33614</li>
                                <li><BsFillTelephoneFill />(813) 443-0771</li>
                                <li>FAQ</li>
                                <li>Stay connected with us!</li>
                            </ul>
                        </Col>
                        <Col lg={3} md={6}>
                            <div className={classes.logo}>
                                <img src={Logo} />
                            </div>
                            <ul>
                                <li> <p className={classes.textWhite}>Vilo Fence provides custom security solutions through Fencing, Railing, Gate Automation in the state of Florida.
                                    Bonded. Licensed. Insured.</p></li>
                                <li>
                                    <ul className={classes.socialIcons}>
                                        <li onClick={()=>redirectOn('facebook')}><FaFacebookF /></li>
                                        <li onClick={()=>redirectOn('instagram')}><FaInstagram /></li>
                                        <li onClick={()=>redirectOn('twitter')}><BsTwitter /></li>
                                        <li onClick={()=>redirectOn('pinterest')}><FaPinterestP /></li>
                                        <li onClick={()=>redirectOn('youtube')}><FaYoutube /></li>
                                    </ul>
                                </li>
                            </ul>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className={classes.lowerFooter}>
                <p> &copy; 2022 Vilo Fence LLC. All Rights Reserved.</p>
            </div>
        </>
    )
}

export default Footer