import React, { useEffect, useState, useContext } from 'react'
import { Col, Row } from 'react-bootstrap'
import { message } from 'antd'
import { IoChevronBack } from 'react-icons/io5'
import { stepperContext } from '../stepperContext'
import { AiTwotoneDelete } from 'react-icons/ai'
import classes from './StepperCard.module.css'
import { http } from '../../services'
import { useNavigate } from 'react-router-dom'


function TotalEstimateAndAddOption({ data, setNext }) {
    const navigate = useNavigate()


    const { setLineData, lineData, index } = useContext(stepperContext)

    async function submitData() {

        const url = `lead`;

        const options = {
            method: "POST",
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify(lineData)
        }

        const response = await http(url, options);

        if (response?.code == 200) {
            message.success(response?.message)
            navigate('/checkout', { state: response?.data })

        } else {

            message.error('Something went wrong')
        }


    }

    return (
        <>
            <Col md={12}>
                <h4>
                    <IoChevronBack color="#272932" className={classes.backIcon} onClick={() => setNext((p) => --p)} />
                    Your Fences
                    <p>Fences</p>
                </h4>
            </Col>
            <div className={classes.cardBody}>
                <Row>
                    <Col md={12} >
                        <Row>
                            <Col md={12}>
                                <div className='d-flex justify-content-between '>
                                    <div className={'d-flex '}>
                                        <span className={`mr-1  ${[classes.numberCircle].join(" ")}`}>1</span>
                                        <h2>{lineData?.length} ft</h2>
                                    </div>
                                    <button className={classes.deleteBtn} onClick={() => setLineData({})}><AiTwotoneDelete /></button>
                                </div>
                                <p className={`{[classes.mt10,classes.textGray].join(" ")}`}>{lineData?.noOfGates} gate</p>
                            </Col>
                            <Col md={12}>
                                <button onClick={submitData} className={`${[classes.btn, classes.mt20].join(" ")}`}>Next</button>
                            </Col>
                            {/*<Col md={12}>
                        <div className={classes.estimateBox}>
                            <p>Do you want to remove the existing fence? </p>
                            <p>$33</p>
                        </div>
                </Col>*/}
                            {/* <Col md={12} >
                                <div className={`${classes.totalEstimateBox} ${classes.mt30}`}>
                                    <p className={classes.textGray}>Total Estimate: </p>
                                    <p>Call for pricing</p>
                                </div>
                            </Col> */}

                        </Row>

                    </Col>
                </Row>
            </div>
        </>
    )
}

export default TotalEstimateAndAddOption