import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import ReactElasticCarousel from 'react-elastic-carousel'
import { useNavigate } from 'react-router-dom'
import CommonHeader from '../../components/CommonHeader'
import Footer from '../../components/Footer/index'
import ReviewCard from '../../components/ReviewCard'
import classes from './ClientReviews.module.css'
import './styles.css'

function ClientReviews() {
    const navigate = useNavigate()
    const data = [
        {
            profile: '/user-img1.jpg', createdAt: '2 months ago',
            description: "Incredible service and great product if you want to save $$$ of your income.Highly Recommend",
            rating: 5,
            userName: 'John Smith'
        },
        {
            profile: '/user-img2.jpg', createdAt: '1 month ago',
            description: "He is such a nice guy I would Highly Recommend him. He Knows how to manage things",
            rating: 5,
            userName: 'George Gill'
        },
        {
            profile: '/user-img3.jpg', createdAt: '4 months ago',
            description: "He has done a great job.Highly Recommend",
            rating: 5,
            userName: 'William Smith'
        },
    ]
    const settings = {
        breakPoints: [
            { width: "320", itemsToShow: 1, itemPadding: [0, 2] },
            { width: "768", itemsToShow: 2, itemPadding: [0, 10] },
            { width: "1024", itemsToShow: 3, itemPadding: [0, 10] },
        ],
        itemsToScroll: 1,
        showArrows: false,

    }
    return (
        <div className={classes.clientReviews}>
            <CommonHeader />
            <div className={classes.headerSection}>
                <div className={classes.overLay}>
                    <Container fluid className={classes.container}>
                        <p className={classes.tagline}>Vilo Fence brings a standard of excellence that you
                            can’t find among the competition.</p>
                        <button className={classes.btn} onClick={() => navigate('/calculator')}>FENCE CALCULATOR</button>
                        <h1 className={classes.heading}>CLIENT REVIEWS</h1>
                    </Container>
                </div>
            </div>
            <div className={classes.cardsSection}>
                <Container fluid className={classes.container}>
                    <ReactElasticCarousel {...settings} >
                        {data?.map(item => (
                            <ReviewCard data={item} />

                        ))}
                    </ReactElasticCarousel>
                </Container>
            </div>

            <Footer />
        </div>

    )
}

export default ClientReviews