import { Steps } from 'antd'
import React from 'react'

function Stepper({ steps, stepNo, setStepNo }) {
    const { Step } = Steps
    return (
        <Steps current={stepNo} direction={'horizontal'}>
            {steps.map((item) => (
                <Step key={item.title} />
            ))}
        </Steps>

    )
}

export default Stepper