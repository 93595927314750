import React, { useState } from "react";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
// import PrivateRoute from "./privateRoute";
// import Nabeel from '../pages/MapPage/Nabeel'
import AddInfoAndFence from "../pages/AddInfoAndFence";
import WoodFencing from "../pages/WoodFencing";
import { stepperContext } from "../components/stepperContext";
import Checkout from "../pages/Checkout";
import ContactUs from "../pages/ContactUs";
import ClientReviews from "../pages/ClientReviews";
import AboutUs from "../pages/AboutUs";
import Gallery from "../pages/Gallery";
import LandingPage from "../pages/LandingPage";



export default function App() {

    return (
        <Router>

                <Routes>
                    <Route path='/contact-us'
                        element={<ContactUs />} />
                    {/* <Route path='/nabeel'
                        element={<Nabeel />} /> */}
                    <Route path='/about-us'
                        element={<AboutUs />} />
                    <Route path='/client-reviews'
                        element={<ClientReviews />} />
                    <Route path='/gallery'
                        element={<Gallery />} />
                    <Route path='/checkout'
                        element={<Checkout />} />
                    <Route path='/'
                        element={<LandingPage />} />

                        <Route path='/calculator'
                        element={<AddInfoAndFence />} />
                        <Route path='/wood-fencing'
                        element={<WoodFencing />} />

                        

                </Routes>
        </Router>
    );
}


