import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import CommonHeader from '../../components/CommonHeader'
import Footer from '../../components/Footer'
import classes from './AboutUs.module.css'
import JoinOurTeam from '../../assets/join-our-team.png'
import Reputation from '../../assets/reputation.png'
import Reliability from '../../assets/reliability.png'
import HandShake from '../../assets/hand-shake.png'


function AboutUs() {
  const data = [
    {
      img: Reputation, title: 'Reputation',
      desc: "Our reputation and dedication to our client’s specific needs and installation are just some of the reasons why more companies and individuals choose Vilo Fence."
    },
    {
      img: Reliability, title: 'Reliability',
      desc: "We have custom-built and installed thousands of fence, railing, automated gates. We guarantee 100% customer satisfaction…our promise to you."
    },
    { img: HandShake, title: 'Performance', desc: "Our reputation and dedication to our client’s specific needs and installation are just some of the reasons why more companies and individuals choose ViloFence." }]


  return (
    <div className={classes.aboutUs}>
      <section className={classes.heroSection}>
        <CommonHeader />
        <div className={classes.bgImg}>
          <div className={classes.overLay}>
            <Container fluid className={classes.container}>
              <Row className={classes.row}>
                <Col md={8} sm={12} xxl={8}>
                  <div className={classes.content}>
                    <h3>About Us</h3>
                    <p>We at Vilo Fence are here to serve the Tampa Bay community with their
                      fence installation needs. Family owned since 2008, we are
                      fence professionals. With decades of experience we can recommend and walk
                      you through what’s best in your situation. We install all standard fence
                      types including PVC, wood, and aluminum. We can also build custom
                      designs with our in house welding shop and powder coating booth, we
                      can control for the best quality at the best price.</p>

                    <p className={classes.mt30}>A fence installation can be daunting, we at Vilo Fence can provide a
                      seamless process for a beautiful backyard.</p>

                    <p className={classes.mt30}>We look forward to meeting with you!</p>
                  </div>
                </Col>
              </Row>
            </Container>

          </div>
        </div>
      </section>
      <section className={classes.whyChooseUS}>
        <Container fluid className={classes.container}>
          <h3>WHY CHOOSE
            US FOR
            FENCE
            INSTALLMENT?</h3>
          <Row style={{justifyContent:'center'}}>
            {data?.map(item => {
              return (
                <Col md={6} sm={12} >
                  <div className={classes.card}>
                    <div className={classes.heading}>
                      <img src={item?.img} /> <h4>{item?.title} </h4>
                    </div>
                    <p>{item?.desc}</p>
                  </div>
                </Col>
              )
            })}

          </Row>
        </Container>

      </section>
      <section className={classes.joinOurTeam}>
        <Container fluid className={classes.container}>
          <Row>
            <Col md={7}>
              <div className={classes.imgBox}>
                <img src={JoinOurTeam} />
              </div>
            </Col>
            <Col md={5}>
              <div className={classes.rightBox}>
                <h3>JOIN OUR TEAM</h3>
                <p className={classes.mt30}>
                  Our number one priority is complete customer
                  satisfaction. It starts with our professional
                  team that provides quality installation, service,
                  customer training, and support. We offer an
                  outstanding work environment with plenty of
                  room for growth and advancement. Without
                  our employees, we would not be where we
                  are at today.

                </p>
                <p className={classes.mt30}>
                  Find out what makes our team so great.</p>
                <button className={`${[classes.mt30,classes.btn].join(' ')}`}>Available Positions</button>
              </div>
            </Col>
          </Row>

        </Container>
      </section>
      <Footer />
    </div>

  )
}

export default AboutUs