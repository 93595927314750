import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Header from '../../components/CommonHeader'
import Stepper from '../../components/Stepper'
import classes from './Checkout.module.css'
import Img from '../../assets/fence-color-1.png'
import { useNavigate, useLocation } from 'react-router-dom'
import AddGateModal from '../../components/AddGateModal'
import { http } from '../../services'

function Checkout() {


    const slug = useLocation()?.state?.slug


    const [data, setData] = useState([])
    const [total, setTotal] = useState()



    async function getData() {
        const url = `lead?slug=${slug}`
        const response = await http(url)
        if (response?.code == 200) {
            setData(response?.data)

        }
    }

    useEffect(() => {
        getData()
    }, [])

    useEffect(() => {
        if (data?.length > 0) {
            const gatePrice = parseInt(data[0]?.gate?.price) * parseInt(data[0]?.noOfGates)
            const fencePrice = parseInt(data[0]?.product?.price) * parseInt(data[0]?.length)
            const finalPrice = gatePrice + fencePrice
            setTotal(finalPrice)
        }
    }, [data])


    const [show, setShow] = useState(false)
    const teams = [
        { displayName: 'Orlando Hernandez', phoneNo: '813-443-0771', image: '/team-member-1.jpeg' },
        { displayName: 'Dariel Concepcion', phoneNo: '813-443-0771', image: '/team-member-2.jpeg' },
        { displayName: 'Yunior Gonzalez', phoneNo: '813-443-0771', image: '/team-member-3.jpeg' },
        { displayName: 'Santiago Linares', phoneNo: '813-443-0771', image: '/team-member-4.jpeg' },
    ]
    const navigate = useNavigate()
    return (
        <div>
            <Header bgColor={'#EFF1ED'} />
            <Container fluid className={classes.container}>
                <Row >
                    <Col md={6} className={classes.mt30}>
                        <Stepper stepNo={5} steps={['', '', '', '', '']} />
                        <h4 className={`${[classes.mt30, classes.black].join(" ")}`}>Thank you for your request!</h4>
                        <p className={classes.big}>We have received your quote request and a sales representative will be contacting you shortly.</p>
                    </Col>
                    <Col md={6}>
                        <div className={classes.btnBox}>
                            <button className={classes.backToHomeBtn} onClick={() => navigate('/')}>Back to Homepage</button>
                        </div>
                    </Col>
                    <Col md={12}>
                        <h4 className={classes.maroonText}>Quote Summary</h4>

                    </Col>
                    <Col md={12}>
                        <Row>
                            {data?.map(item => {
                                return (
                                    <>
                                        <Col md={12}>
                                            <div className={classes.summaryBox}>
                                                <div style={{ width: '60%' }}>
                                                    <Row className='gy-0'>
                                                        <Col md={5}>
                                                            <div className={classes.left}>
                                                                <span className={classes.numberCircle}>1</span>
                                                                <h5 className={classes.black}>{item?.length} Ft.</h5>
                                                            </div>
                                                        </Col>
                                                        <Col md={7}>
                                                            <p className={classes.gray}>
                                                                {item?.noOfGates} {item?.gate?.name} Gate
                                                            </p>
                                                            <p className={classes.gray}>
                                                                {item?.product.title}
                                                            </p>
                                                        </Col>
                                                    </Row>
                                                </div>

                                                <div>
                                                    <h5 className={classes.maroonText}>${total} - ${total + 700}</h5>
                                                </div>
                                            </div>
                                        </Col>

                                    </>
                                )
                            })}
                            <Col md={6} >
                                <div className={` ${[classes.totalEstimate, classes.mb30, classes.mt24].join(" ")}`}>
                                    <h5 className={classes.bigGray}>Total Estimate:</h5>
                                    <h5 style={{ marginLeft: '5px' }} className={classes.maroonText}>${total} - ${total + 700}</h5>
                                </div>
                            </Col>


                        </Row>
                    </Col>
                    {/* Our Team */}
                    <Col md={6} >
                        <div className={classes.salesTeamBox}>
                            <h5 className={classes.black}>
                                Our Sales Team
                            </h5>
                            <Row className={''}>
                                {teams?.map(item => (<Col md={4} xxl={3} sm={6} xs={12}>
                                    <div className={classes.salesTeamCard}>
                                        <img src={item.image} />
                                        <p className={`${[classes.mt5].join(" ")}`}>{item?.displayName}</p>
                                        {/* <p className={classes.red}>Email Me</p> */}
                                        <p className={`${[classes.mt5].join(" ")}`}>{item?.phoneNo}</p>
                                    </div>
                                </Col>))}
                            </Row>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className={classes.addressBox}>
                            <p className={classes.grayText}>Questions? Comments? Concerns?</p>
                            <p className={`${[classes.grayText, classes.mt5].join(" ")}`}>Please don't hesitate to contact us for any reason۔</p>
                            <p className={`${[classes.mt20].join(" ")}`}>813-443-0771</p>
                            <p className={`${[classes.mt10].join(" ")}`}>3805 w Osborne ave Tampla FL 33614</p>
                            {/* <p className={`${[classes.mt10].join(" ")}`}>123 Fake St Springfield, USA</p> */}
                        </div>
                    </Col>

                </Row>
            </Container>
            <AddGateModal show={show} setShow={setShow} />
        </div>
    )
}

export default Checkout

// <Col md={6}>
// <div className={` ${[classes.propInfoBox, classes.mb30].join(" ")}`}>
//     <h2 className={classes.black}>Property Information:</h2>
//     <p className={classes.bigGray}>Haugen, WI 54841</p>
//     <p className={classes.bigGray}>Haugen, WI 54841</p>
//     <p className={classes.bigGray}>Haugen, WI 54841</p>
// </div>
// </Col>
