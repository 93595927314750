import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Footer from '../../components/Footer'
import classes from './Gallery.module.css'
import Img1 from '../../assets/gallery-img1.png'
import Img2 from '../../assets/gallery-img2.png'
import Img3 from '../../assets/gallery-img3.png'
import CommonHeader from '../../components/CommonHeader'
import { useNavigate } from 'react-router-dom'

export default function Gallery() {
    const navigate=useNavigate()

    const images = [Img1, Img2, Img3, Img1, Img2, Img3, Img1, Img2, Img3,]
    
    return (
        <div className={classes.gallery}>
                <CommonHeader backgroundColor={'transparent'} />
            <div className={classes.headerSection}>
                <div className={classes.overLay}>
                    <Container fluid className={classes.container}>
                        <p className={classes.tagline}>Vilo Fence brings a standard of excellence that you
                            can’t find among the competition.</p>
                        <button className={classes.btn} onClick={()=>navigate('/calculator')}>FENCE CALCULATOR</button>
                        <h1 className={classes.heading}>GALLERY</h1>
                    </Container>
                </div>
            </div>
            <div className={classes.imageSection}>
                <Container fluid className={classes.container}>
                    <Row>{images?.map(item => {
                        return (
                            <Col md={4} sm={6} xs={12}>
                                <div className={classes.imageCard}><img src={item} /></div>
                            </Col>
                        )
                    })}

                    </Row>
                </Container>
            </div>

            <Footer />
        </div >

    )
}