import React, { useContext, useEffect, useState } from 'react'
import { IoChevronBack } from 'react-icons/io5'
import { BiRightArrowAlt } from 'react-icons/bi'
import ImageCardWithTitle from '../ImageCardWithTitle'
import { stepperContext } from '../stepperContext'
import { Col, Row } from 'react-bootstrap'
import { Spin } from 'antd'
import _ from 'lodash'
import classes from './StepperCard.module.css'
import { LoadingOutlined } from '@ant-design/icons';
import './stepperCard.css'

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

function FenceSelectionsData({ loading, data, next, setNext, category, setCategory, onNext, selected, setSelected, stepPurpose, getProducts }) {


    const [filterData, setFilterData] = useState([])
    const [filterBy, setFilterBy] = useState('4')
    const [renderData, setRenderData] = useState(
        <div className='no-data'>
            <Spin indicator={antIcon} />
        </div>
    )

    function filterOutData() {
        const searchResult = data?.filter(item => item.height === filterBy)
        setFilterData(searchResult)
    }

    useEffect(() => {
        if (stepPurpose === 'product') {
            filterOutData()
        }
    }, [data, filterBy])


    useEffect(() => {
        let cards;
        if (stepPurpose === 'product') {
            if (!filterData?.length) {
                cards = (
                    <div className='no-data'>
                        <h3>No records added yet...</h3>
                    </div>
                )
            }
            else {
                cards = filterData?.map((item, i) => (
                    <Col key={item.slug} md={4}>
                        <ImageCardWithTitle onClick={setSelected} state={selected} data={item} />
                    </Col>
                ))
                setRenderData(cards)
            }
        }
        else {
            if (!data?.length) {
                cards = (
                    <div className='no-data'>
                        <h3>No records added yet...</h3>
                    </div>
                )
            }
            else {
                cards = data?.map((item, i) => (
                    <Col key={item.slug} md={4}>
                        <ImageCardWithTitle onClick={setSelected} state={selected} data={item} />
                    </Col>
                ))
            }
            setRenderData(cards)
        }
    }, [data, filterData])

    const {
        lineData, setLineData,
    } = useContext(stepperContext)

    function onNextClick() {

        if (stepPurpose === 'category') {
            const newLineData = _.cloneDeep(lineData);
            newLineData.category = selected;
            setLineData(newLineData)
            getProducts()
            setNext(p => ++p)
        }
        else {
            const newLineData = _.cloneDeep(lineData);
            newLineData.product = selected;
            setLineData(newLineData)
            setNext(p => ++p)
        }

    }

    return (
        <>
            <Col md={12}>
                <h4>
                    <IoChevronBack
                        color="#272932"
                        className={classes.backIcon}
                        onClick={() => setNext((p) => --p)}
                    />
                    Select the fence
                    <p className={classes.mb20}>Select the fence as per your requirement.</p>
                </h4>
            </Col>
            {
                stepPurpose === 'product' &&
                <Col md={12}>
                    <div className={`${classes.mb20} ${classes.selectBox}`}>
                        <span>Height</span>
                        <select className={classes.select} onChange={(e) => setFilterBy(e.target.value)}>
                            <option value="4">4 ft</option>
                            <option value="6">6 ft</option>
                        </select>
                    </div>
                </Col>
            }
            <div className={classes.cardBody}>
                <Row style={{ width: '100%' }}>
                    <Col md={12} >
                        <Row style={{ width: '100%', minHeight: '55vh' }}>
                            {renderData}
                        </Row>
                        <button
                            className={`w-100 mb-4 ${[classes.btn].join(' ')}`}
                            disabled={selected == null && true}
                            onClick={onNextClick}
                        >
                            Next
                            <BiRightArrowAlt style={{ fontSize: '25px' }} />
                        </button>

                    </Col>
                </Row>
            </div>

        </>

    )
}

export default FenceSelectionsData

