import { Col, Row } from 'react-bootstrap'
import React, { useContext, useEffect, useState } from 'react'
import { Steps } from 'antd'
import classes from './StepperCard.module.css'
import './stepperCard.css'
import { IoChevronBack } from 'react-icons/io5'
import { stepperContext } from '../stepperContext'
import { useNavigate } from 'react-router'
import Step3WhenLineCreated from './Step3WhenLineCreated'
import FenceSelectionsData from './FenceSelectionsData'
import TotalEstimateAndAddOption from './TotalEstimateAndAddOption'
import Stepper from '../Stepper'
import http from '../../services/api'
import AutoComplete from '../AutoComplete'
import _ from 'lodash'

const { Step } = Steps

const steps = [
    {
    },
    {
    },
    {
    },
    {
    },
    {
    },
];

function StepperCard({
    state,
    setState,
    gate,
    noOfGates,
    length,
    dottedLength,
    setModalVisible,
    setPlace,
    fullLengthArray,
    setFullLengthArray,
    markers,
    setMarkers
}) {

    const navigate = useNavigate()
    const [next, setNext] = useState(0)
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [phoneNo, setPhoneNo] = useState('')
    const [address, setAddress] = useState('')
    const [category, setCategory] = useState(null)
    const [stepNo, setStepNo] = useState(0)
    const [selectedCategory, setSelectedCategory] = useState(null)
    const [selectedProduct, setSelectedProduct] = useState(null)
    //  New Data
    const [categoriesData, setCategoriesData] = useState([])
    const [productsData, setProductsData] = useState([])

    const [isLoading, setIsLoading] = useState(false)

    const {
        lineData, setLineData,
    } = useContext(stepperContext)


    async function getCategories() {
        setIsLoading(true)
        const url = `category`;
        const response = await http(url);
        if (response?.code == 200) {
            setIsLoading(false)
            // console.log({response})
            setCategoriesData(Array.isArray(response?.data) ? response?.data : [response.data])

        }
    }

    async function getProducts() {
        setIsLoading(true)
        const url = `product?categorySlug=${selectedCategory}`;
        const response = await http(url);
        if (response?.code == 200) {
            setIsLoading(false)
            setProductsData(Array.isArray(response?.data) ? response?.data : [response.data])

        }
    }

    function stepOne() {
        setStepNo(1)
        let step1 = {
            firstName, lastName, email, phoneNo
        }
        setLineData(step1)
    }

    function stepTwo() {
        setStepNo(2)
        const stepOneData = _.cloneDeep(lineData)
        stepOneData.address = address;
        setLineData(stepOneData)
    }

    useEffect(() => {
        getCategories()
    }, [])

    return (
        <div className={classes.card}>
            <style>{`.pac-container{
            border:none !important;
        }`}</style>
            <Row style={{ height: '80%' }}>
                <Col md={12} sm={12} className={classes.mb30}>
                    <Stepper stepNo={stepNo} steps={steps} />
                </Col>

                {stepNo == 0 &&
                    <>
                        <Col md={12}>
                            <h4>Your contact info</h4>
                            <p className={classes.mb50}>Enter your contact information and click the NEXT button
                                to start building your fence.</p>
                        </Col>
                        <div className={classes.cardBody}>
                            <Row>
                                <Col md={6} className={classes.mb20}>
                                    <div className={classes.inputWithLabel}>
                                        <label htmlFor={'first-name'} >First Name</label>
                                        <input type={'text'} id='first-name' value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                                    </div>
                                </Col>
                                <Col md={6} className={classes.mb20}>
                                    <div className={classes.inputWithLabel}>
                                        <label type={'text'} htmlFor={'last-name'}>Last Name</label>
                                        <input id='last-name'
                                            value={lastName} onChange={(e) => setLastName(e.target.value)}
                                        />
                                    </div>
                                </Col>
                                <Col md={12} className={classes.mb20}>
                                    <div className={classes.inputWithLabel}>
                                        <label
                                            htmlFor={'email'}
                                        >Email</label>
                                        <input id='email'
                                            type={'email'}
                                            value={email} onChange={(e) => setEmail(e.target.value)}
                                        />
                                    </div>
                                </Col>
                                <Col md={12}>
                                    <div className={classes.inputWithLabel}>
                                        <label htmlFor={'phone'}>Phone</label>
                                        <input id='phone'
                                            value={phoneNo} onChange={(e) => setPhoneNo(e.target.value)}
                                        />
                                    </div></Col>
                                <Col md={12}>
                                    <div className='d-flex flex-column align-items-center'>
                                        <button className={`${[classes.btn, classes.mt40].join(" ")}`} onClick={stepOne}
                                            disabled={(firstName == '' || lastName == '' || phoneNo == '' || email == '') ? true : false}
                                        >Next</button>
                                        <p className={`${[classes.mt10, classes.pb40].join(" ")}`} >Back to Home Page</p>
                                    </div>
                                </Col>
                            </Row>
                        </div>

                    </>
                }
                {stepNo == 1 &&
                    <>
                        <Col md={12}>
                            <h4>
                                <IoChevronBack color="#272932" className={classes.backIcon} onClick={() => setStepNo(p => --p)} />
                                Find your home
                            </h4>
                            <p>Enter your address, and click the Find Your Home
                                button to locate your home on the map.</p>
                        </Col>
                        <div className={classes.cardBody}>
                            <Row>
                                <Col md={24} className={classes.mb50}>
                                    <div className={classes.inputWithLabel}>
                                        <label htmlFor={'address'}>Address City, State Zip</label>
                                        <p className={classes.addressExp}>ex: 123 Maple St. Metropolis, KS 68333</p>
                                        <AutoComplete setPlace={setPlace} address={address} setAddress={setAddress} />
                                    </div >
                                </Col >
                                <Col md={12}>
                                    <div className='d-flex flex-column align-items-center'>
                                        <button className={`${[classes.btn].join(" ")}`}
                                            onClick={stepTwo}
                                        >Next</button>
                                        <p className={`${[classes.mt10, classes.pb40].join(" ")}`} >Back to Home Page</p>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </>

                }
                {
                    stepNo == 2 &&
                    <>
                        {next == 0 &&
                            <Step3WhenLineCreated
                                setModalVisible={setModalVisible}
                                length={length}
                                dottedLength={dottedLength}
                                setStepNo={setStepNo}
                                setNext={setStepNo}
                                state={state}
                                setState={setState}
                                gate={gate}
                                noOfGates={noOfGates}
                                fullLengthArray={fullLengthArray}
                                setFullLengthArray={setFullLengthArray}
                                markers={markers}
                                setMarkers={setMarkers}
                            />
                        }
                    </>
                }
                {stepNo == 3 &&
                    <>
                        <FenceSelectionsData
                            loading={isLoading}
                            data={categoriesData} next={next}
                            setNext={setStepNo}
                            selected={selectedCategory}
                            setSelected={setSelectedCategory}
                            stepPurpose='category'
                            getProducts={getProducts}
                        />

                    </>
                }
                {stepNo == 4 && productsData ?
                    <>
                        <FenceSelectionsData
                            loading={isLoading}
                            data={productsData} next={next}
                            setNext={setStepNo}
                            selected={selectedProduct}
                            setSelected={setSelectedProduct}
                            stepPurpose='product'
                        />

                    </>
                    :
                    <></>
                }
                {
                    stepNo == 5 &&
                    <TotalEstimateAndAddOption />
                }
            </Row >

        </div >
    )
}

export default StepperCard