import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Footer from '../../components/Footer'
import classes from './woodFencing.module.css'
import Img1 from '../../assets/bg-img.png'
import CommonHeader from '../../components/CommonHeader'

export default function WoodFencing() {

    const lastCards = [
        { title: 'Clean Up Your Yard', description: 'Chances are, your yard is already in good shape if you give it regular maintenance. However, before our crew arrives, make sure you’ve cleared stray debris or other miscellaneous items off the ground that could interfere with the work.', img: Img1 },
        { title: 'Clean Up Your Yard', description: 'Chances are, your yard is already in good shape if you give it regular maintenance. However, before our crew arrives, make sure you’ve cleared stray debris or other miscellaneous items off the ground that could interfere with the work.', img: Img1 },
        { title: 'Clean Up Your Yard', description: 'Chances are, your yard is already in good shape if you give it regular maintenance. However, before our crew arrives, make sure you’ve cleared stray debris or other miscellaneous items off the ground that could interfere with the work.', img: Img1 },

    ]

    return (
        <div className={classes.woodFencing}>
                <CommonHeader backgroundColor={'transparent'} />
            <div className={classes.headerSection}>
                <div className={classes.overLay}>
                    <Container fluid className={classes.container}>
                        <h1 className={classes.heading}>WOOD FENCING</h1>
                        <p className={classes.tagline}>Wood fencing provides a warm, natural feel to your property. Choose from our wide selection
                        of materials, including Cypress, Cedar, and Pressure Treated Pine. All of our wood gates are
                        framed with aluminum for durability and alignment and our gate hardware is powder-coated.</p>
                    </Container>
                </div>
            </div>
            {/* Cards */}
            <div className={classes.lastCards}>
                <Container fluid className={classes.container}>
                    {
                        lastCards?.map((item, i) => (
                            <Row className={classes.cardBody}>

                                <Col md={{ span: 6, order: i == 1 && 3 }}>
                                    <div className={classes.imgBox}>
                                        <img src={item?.img} />
                                    </div>
                                </Col>
                                <Col md={{ span: 6, order: i == 1 && 2 }}>
                                    <div className={classes.content}>
                                        <h4>{item?.title}</h4>
                                        <p>{item?.description}</p>
                                    </div>
                                </Col>
                            </Row>
                        ))
                    }
                </Container>
            </div>

            <div className={classes.customFabrication}>

            <div className={classes.overLay}>
                <Container fluid className={classes.container}>
                    <h3 className={classes.mb32}>CUSTOM FABRICATION</h3>
                    <p className={classes.mb32}>Let us help you design a fence that will add value and security to your property today. Terms of financing are variable based on amount borrowed, credit worthiness, and other factors. Contact us for specific details.</p>
                    <button>Get in touch with us today!</button>
                </Container>
            </div></div>



            
            <Footer />
        </div >

    )
}