import React from 'react';
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from 'react-places-autocomplete';
import './auto-complete.css'

export default function AutoCompleteComp({ address, setAddress, setPlace }) {

    const handleChange = address => {
        setAddress(address);
    };

    async function handleSelect(address) {

        const geoCode = await geocodeByAddress(address)

        setAddress(geoCode[0].formatted_address)

        const latLng = await getLatLng(geoCode[0])

        setPlace(latLng)

    };

    return (
        <PlacesAutocomplete
            value={address}
            onChange={handleChange}
            onSelect={handleSelect}
        >
            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                <div>
                    <input
                        {...getInputProps({
                            placeholder: 'Search Places ...',
                            className: 'location-search-input',
                        })}
                    />
                    <div className="autocomplete-dropdown-container">
                        {loading && <div style={{ backgroundColor: '#fafafa', padding: '5px' }}>Loading...</div>}
                        {suggestions.map(suggestion => {
                            const className = suggestion.active
                                ? 'suggestion-item--active'
                                : 'suggestion-item';
                            // inline style for demonstration purpose
                            const style = suggestion.active
                                ? { backgroundColor: '#fafafa', cursor: 'pointer', borderBottom: '1px solid black', padding: '5px' }
                                : { backgroundColor: '#ffffff', cursor: 'pointer', borderBottom: '0.5px solid #dedede', padding: '5px' };
                            return (
                                <div
                                    {...getSuggestionItemProps(suggestion, {
                                        className,
                                        style,
                                    })}
                                >
                                    <span>{suggestion.description}</span>
                                </div>
                            );
                        })}
                    </div>
                </div>
            )}
        </PlacesAutocomplete>
    )
}