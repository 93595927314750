import React, { useState, useEffect } from 'react'
import { Container, Form, FormControl, Nav, Navbar } from 'react-bootstrap'
import Logo from '../../assets/logo.png'
import classes from './CommonHeader.module.css'
import { FaFacebookF, FaInstagram, FaTwitter, FaPinterestP, FaYoutube } from 'react-icons/fa'
import { NavLink } from 'react-router-dom'
function CommonHeader({ backgroundColor }) {
    const [pos, setPos] = useState(false)
    const [bgColor, setBgColor] = useState(backgroundColor)

    useEffect(() => {
        window.addEventListener('scroll', () => {
            if (window.scrollY > 300) {
                setBgColor(null)
                setPos(true)
            }
            else {
                setBgColor(backgroundColor)
                setPos(false)
            }

        })
    }, [])

    return (
        <>
            <style jsx>{`
            .${classes.header}.navbar-light{
                background-color:white !important;
                color:'white';
                box-shadow: 0px 2px 7px 0px rgba(0,0,0,0.25);
-webkit-box-shadow: 0px 2px 7px 0px rgba(0,0,0,0.25);
-moz-box-shadow: 0px 2px 7px 0px rgba(0,0,0,0.25);
            }
            .${classes.socialRelated}>div p{
                color:white;
            }
     .navbar-expand-lg, .navbar-expand-xl {
     flex-wrap: wrap !important; 
         }
 .navbar-light .navbar-nav .nav-link{
             color:#272932 !important;
         }
         .navbar-light .navbar-nav .nav-link:hover{
             color:#A20427 !important;
         }
         .navbar-light .navbar-toggler{
             background-color:#E7ECEF;

         }
         .navbar-light button.navbar-toggler{
             box-shadow:unset !important;
             width:46px;
             height:28px;
             padding:unset;
             display:flex;
             justify-content:center;
             align-items:center;
             

         }
         .navbar-light button.navbar-toggler span{
                font-size: 12px !important;
                line-height: 16px !important;

         }
         @media screen and (min-width:1200px){
            .navbar-light button.navbar-toggler{
                display:none;
            }
         }
        `}</style>
            <Navbar expand="xl" className={classes.header} fixed={pos && 'top'} >
                <Container fluid className={classes.container}>
                    <Navbar.Toggle aria-controls="navbarScroll" />
                    <Navbar.Brand href="#" className='m-auto'>
                        <img className={classes.logo} src={Logo} />
                    </Navbar.Brand>
                    <Navbar.Collapse id="navbarScroll">
                        <Nav
                            className="ms-auto my-2 my-lg-0"
                        >
                            <NavLink style={({ isActive }) => isActive ? { color: '#A20427' } : undefined} className={classes.link} to="/">Home</NavLink>
                            <NavLink style={({ isActive }) => isActive ? { color: '#A20427' } : undefined} className={classes.link} to="/gallery">Gallery</NavLink>
                            <NavLink style={({ isActive }) => isActive ? { color: '#A20427' } : undefined} className={classes.link} to="/contact-us">Contact Us</NavLink>
                            <NavLink style={({ isActive }) => isActive ? { color: '#A20427' } : undefined} className={classes.link} to="/about-us">About Us</NavLink>
                            <NavLink style={({ isActive }) => isActive ? { color: '#A20427' } : undefined} className={classes.link} to="/client-reviews">Client Reviews</NavLink>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>
    )
}

export default CommonHeader